import React from "react"
import { graphql } from "gatsby"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: "center",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingBottom: "30px",
    backgroundColor: "#fff",
  },
  title: {
    marginTop: 0,
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  subtitle: {
    marginTop: "2rem",
    marginBottom: "0.5rem",
    textAlign: "left",
  },
  content: {
    textAlign: "left",
  },
  a: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
}))

const AboutPage = ({ data, location }) => {
  const classes = useStyles()
  const { twitter } = data

  return (
    <Layout dark>
      <Container maxWidth="md" className={classes.container}>
        <Typography variant="h1" className={classes.title}>
          About Simpleen.io
        </Typography>
        <Typography variant="h2" className={classes.subtitle}>
          Translate Software & Documentation
        </Typography>
        <Typography variant="body1" className={classes.content}>
          Simpleen is a localization tool for developers, product managers and
          creators who want to translate their App, Software, Game or
          Documentation into other languages. <br />
          <br />
          It provides a dependency-free and fast localization service that
          instantly translates your locale files. All text segments are stored
          seperately to reuse and edit them anytime.
          <br />
          <br />
          In addition, the Simpleen CLI provides you with continuous translation
          results during the product development process. You can adapt your
          translation results directly in your locale files and sync them with
          Simpleen. We will use the adapted result instead of translate them
          again.
        </Typography>
        <Typography variant="h2" className={classes.subtitle}>
          Why Simpleen?
        </Typography>
        <Typography variant="body1" className={classes.content}>
          Living in a small country that has four official languages we know
          what it means to localize software into multiple languages. It is most
          likely a process that will be postponed due to costs, coordination
          efforts and the provided texts are just not final yet.
          <br />
          <br />
          We wanted to provide our products as early as possible in multiple
          languages to present them to customers, stakeholders and users which
          provided us with feedback and new ideas. So we started on our mission
          to speed up the development of multilingual projects and this is when
          Simpleen came to life.
          <br />
          <br />
        </Typography>
        <Typography variant="h2" className={classes.subtitle}>
          What makes Simpleen a faster Localization Tool?
        </Typography>
        <Typography variant="body1" className={classes.content}>
          <ul>
            <li>
              We do provide suitable, preconfigured translators for your
              techstack and you can copy/paste or drag/drop your files
            </li>
            <li>We do provide machine translation for instant results</li>
            <li>We keep variables/interpolations of i18n libraries</li>
            <li>We selectively translate documentations</li>
            <li>We do support continuous translations with the CLI</li>
            <li>
              You can edit your locales within your IDE and upload them with one
              command
            </li>
            <li>We don't need any access to your repository</li>
          </ul>
          In comparison to other localization solutions we focus on the product
          team instead of the translator. We're bringing better machine
          translation results and accessibility to your localization project.
        </Typography>
      </Container>
      <Seo
        general={{
          title: "About Simpleen.io",
          description: "About our product",
          language: "en",
          path: location.pathname,
          image: twitter.childImageSharp.gatsbyImageData.src,
        }}
      />
    </Layout>
  )
}

export const privacyQuery = graphql`
  query SimpleenAboutPageQuery {
    twitter: file(relativePath: { eq: "seo/SEOImageFormat.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 95
          placeholder: BLURRED
          layout: FIXED
        )
      }
    }
  }
`

export default AboutPage
